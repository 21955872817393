<template></template>

<script>
/*
see https://docs.cidaas.com/docs/cidaas-iam/1ipae577qow1b-o-auth2-flows#pkce-flow-development-perspective
*/

import { config } from "@/config";
import store from "@/store";
import randomString from "@/utils/RandomString";

const crypto = require("crypto");
const base64url = require("base64url");

export default {
  name: "LoginCidaas",
  props: {
    redirectUri: {
      type: String,
      required: false,
    },
  },
  created() {
    const url = new URL(config.loginRedirectUrl);
    const code_verifier = randomString(32);
    const state = randomString(32);

    store.commit("setAuthStatus", {
      authState: state,
      authVerifier: code_verifier,
    });

    var hash = crypto.createHash("sha256").update(code_verifier).digest();
    var code_challenge = base64url.encode(hash);
    var redirectUri =
      this.redirectUri || config.frontendUrl + "/login-callback";

    console.log(
      ">>> login: store state/code_verifier and redirect to ",
      redirectUri,
      "...",
      state,
      code_verifier
    );

    url.searchParams.set("response_type", "code");
    url.searchParams.set("response_mode", "query");
    url.searchParams.set("client_id", config.cidaasClientId);
    url.searchParams.set("scope", "profile");
    url.searchParams.set("code_challenge", code_challenge);
    url.searchParams.set("code_challenge_method", "S256");
    url.searchParams.set("state", state);
    url.searchParams.set("view_type", "login");
    url.searchParams.set("redirect_uri", redirectUri);
    window.location.href = url.toString();
  },
};
</script>
